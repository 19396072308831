import styled from 'styled-components'

export const Wrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background:white;
`

export const Title = styled.h2`
    margin: 6.5rem 0 4rem;
    font-size:2.25rem;
    text-align:center;
    color:#00172b;
`

export const Body = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    width:58rem;
    max-width:calc(100% - 5rem);
    margin-bottom:4.5rem;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        flex-direction:column;
    }
`
export const Post = styled.div`
    width:45%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        width:100%;
        margin-bottom:2.25rem;
    }
`

export const Image = styled.img`
    width:100%;
    margin-bottom:1.5rem;
    object-fit:cover;
    height:11rem;
`
export const Label = styled.a`
    line-height:1.5;
    font-weight:600;
    color:#ff4940;
    cursor:pointer;
    text-decoration:none;
    transition:color 150ms ease-out;
    &:hover, &:focus {
        text-decoration:underline;
        color:#FF625B;
    }
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        margin-top:.25rem;
        font-size:1.5rem;
    }
`
export const Description = styled.p`
    line-height:1.5;
    letter-spacing:.08px;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        font-size:1.25rem;
    }
`
