import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
import { Wrap, Title, Body, Post, Image, Label, Description } from './_styles'

//TODO: Add loading placeholders, interface for navigating through old posts, etc.
export default class News extends Component {

    constructor(props){
        super(props);
        this.state = {
            fetchingPosts: true,
            posts: []
        }
    }

    componentDidMount = async () => {
        const url = `https://${process.env.REACT_APP_API_HOST}/v1/blog/posts`;
        const res = await fetch(url,{
            method:'GET',
            headers: { 'Content-Type': 'application/json' }
        })
        const response = await res.json();
        if ( response.success ){
            this.setState({
                fetchingPosts:false,
                posts:response.data
            })
        } else {
            this.setState({ fetchingPosts: false })
        }
    }

    Posts = ( postData ) => {
        const posts = postData.map( post => (
            <Post key={post.title}>
                <Image src={post.image} alt={`${post.title}-image`} />
                <Label href={post.link} rel="noopener noreferrer" target="_blank">
                    {post.title}
                </Label>
                <Description>
                    {post.summary}
                </Description>
            </Post>
        ))
        return posts;
    }

    render(){
        const { Posts } = this;
        const { fetchingPosts } = this.state;
        const posts = this.state.posts.slice(0,2);

        return (
            <Wrap>
            <Title>Latest from us</Title>
            <Body>
                { !fetchingPosts && posts.length > 0 ?
                    <>{ Posts( posts )}</> : null
                }
            </Body>
        </Wrap>
        )
    }
}