import styled from 'styled-components'

export const Wrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background:#00172B;
    color:white;
`

export const Title = styled.h2`
    margin: 6.5rem 0 4rem;
    max-width:calc(100% - 4rem);
    font-size:2.25rem;
    text-align:center;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        margin-bottom:2rem;
        font-size:2.125rem;
    }
`

export const Body = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    width:58rem;
    max-width:calc(100% - 3rem);
    margin-bottom:4.5rem;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        flex-direction:column;
        margin-bottom:6rem;
    }
`
export const BodyColumn = styled.div`
    width:43%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        width:100%;
        margin: 2rem 0 0;
        h6, p { 
            text-align:center
        }
    }
`

export const Icon = styled.img`
    margin-bottom:2rem;
    height:8.5rem;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        margin-right:3.5rem;
    }
`

export const Label = styled.h6`
    margin: 1.125rem 0 .5rem;
    font-size:.9375rem;
    font-weight:600;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        font-size:1.5rem;
    }
`

export const Description = styled.p`
    line-height:1.5;
    font-size:.9375rem;
    letter-spacing:.08px;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        font-size:1.25rem;
    }
`

export const CodeSnippet = styled.pre`
    padding: .875rem 1.125rem;
    background:#001425;
    border:1px solid #0F4C82;
    border-radius:10px;
    font-family:'Roboto Mono';
    font-size:.6875rem;
    line-height:1.625;
`