import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
import { Wrap, Body, Title, Subtitle, WaitList, Form, Prompt, SuccessText, Error, Email, Submit, Images, IPhone, Macbook } from './_styles'

import iPhone from '../../images/iPhone.png'
import macbook from '../../images/macbook.png'

export default class Cover extends Component {
    
    body = null;
    constructor(props){
        super(props)
        this.state = {
            windowWidth:null,
            macHeight:null,
            macWidth:null,
            email:'',
            submitting:false,
            submitSuccess:null,
            errorCode:null
        }
    }

    componentDidMount(){
        // Staggered fadeIn to hide load times for font & images
        setTimeout(() => this.body.style.opacity = 1, 350)
        this.setMacbookDimensions();
        window.addEventListener('resize', this.setMacbookDimensions.bind(this))
    }

    //Ugly function to fix a problem with the macbook image height on mobile
    setMacbookDimensions(){
        this.setState({
            windowWidth:window.outerWidth,
            macHeight: `${Math.floor((window.outerWidth - 36) * .43467012)}`,
            macWidth: `${Math.floor(window.outerWidth)}`,
        })
    }


    updateEmail( e ){
        this.setState({ submitSuccess: null, email:e.target.value })
    }

    subscribe = async () => {
        const { email } = this.state;

        if ( email !== '' ){
            this.setState({ submitting: true }, async () => {
                try {
                    const url = `https://${process.env.REACT_APP_API_HOST}/v1/waitlist`
                    const res = await fetch(url, {
                    method:'POST',
                    body:JSON.stringify({ email }),
                    headers: { 'Content-Type': 'application/json' }
                    })
                    res.status === 200 ?
                        this.setState({ submitSuccess:true, submitting:false }) :
                        this.setState({ submitSuccess:false, submitting:false, errorCode:res.status })
                } catch ( error ){
                    //TODO: Actually handle different error types
                    this.setState({ submitSuccess:false, submitting: false })
                }
            })
        }
    }
    
    render(){
        const { windowWidth, macHeight, macWidth, email, submitting, submitSuccess, errorCode } = this.state;
        const { subscribe, updateEmail } = this;
        return (
            <Wrap>
                <Body ref={ref => this.body = ref}>
                    <Title>Log into Ethereum</Title>
                    <Subtitle>
                        No downloads, no seed phrases.
                        <br />
                        Any browser, any time, mobile or desktop.
                    </Subtitle>
                    <WaitList>
                        <Prompt>
                            Join the waitlist for early access
                        </Prompt>
                        { submitSuccess === false ? <Error>{errorCode === 400 ? `You've already joined!` : `Something went wrong, please try again!`} </Error> : null }
                        { submitSuccess ? 
                            <SuccessText>
                                Thanks for joining. We'll email you when you're approved.
                            </SuccessText> 
                            :
                            <Form onSubmit={e => e.preventDefault() && subscribe() }>
                                { submitting ? 
                                    <Email name="email" placeholder="email" value={ email } disabled />
                                :   <Email 
                                        name="email" placeholder="Email"
                                        value={ email }
                                        onChange={ updateEmail.bind(this) }
                                        onKeyDown={e => e.keyCode === 13 ? subscribe() : false }
                                    />
                                }
                                <Submit
                                    data-submitting={submitting}
                                    onClick={ !submitting && submitSuccess !== true ? subscribe : false }>
                                    { submitting ? `Joining...` : `Join Waitlist` }
                                </Submit>
                        </Form>
                        }
                    </WaitList>
                    <Images>
                        <IPhone src={iPhone} alt="iPhone showing app"/>
                        <Macbook 
                            style={ windowWidth && windowWidth < 720 ? { height:`${macHeight}px`, width:`${macWidth}px` } : { height:'auto' }} 
                            src={macbook} alt="Macbook showing app"
                        />
                    </Images>
                </Body>
            </Wrap>
        )
    }
}
