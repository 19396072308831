import React, { Component } from 'react'

import { Wrap, Body, Links, Link, Copyright } from './_styles'

export default class Footer extends Component {
    render() {
        return (
            <Wrap>
                <Body>
                    <Copyright>© {(new Date()).getFullYear()} Authereum</Copyright>
                    <Links>
                        <Link href="https://twitter.com/authereum" rel="noopener noreferrer" target="_blank">Twitter</Link>
                        <Link href="https://t.me/authereum" rel="noopener noreferrer" target="_blank">Telegram</Link>
                        <Link href="https://medium.com/authereum" rel="noopener noreferrer" target="_blank">Medium</Link>
                        <Link href="https://github.com/authereum" rel="noopener noreferrer" target="_blank">GitHub</Link>
                        <Link href="/developers" rel="noopener noreferrer" target="_blank">Developers</Link>
                        <Link href="https://s3.amazonaws.com/assets.authereum.org/authereum_press_kit.zip" rel="noopener noreferrer" target="_blank">Press Kit</Link>
                    </Links>
                </Body>
            </Wrap>
        )
    }
}
