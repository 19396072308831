import React, { Component } from 'react'
import { Wrap, Title, FeatureWindow, Feature, Icon, Label, Description } from './_styles'

import mobileIcon from '../../images/icon-mobile-experience.png'
import seamlessIcon from '../../images/icon-seamless-onboarding.png'
import peacefulMindIcon from '../../images/icon-peace-of-mind.png'

export default class Features extends Component {

    wrap = null;
    constructor(props){
        super(props)
        this.state = {}
    }

    componentDidMount(){
        // Staggered fadeIn to match load-in for cover
        setTimeout(() => this.wrap.style.opacity = 1, 400)
    }
    
    render() {
        return (
            <Wrap ref={ref => this.wrap = ref}>
                <Title>Features</Title>
                <FeatureWindow>
                    <Feature>
                        <Icon style={{ backgroundColor:'#ffba52' }}>
                            <img src={mobileIcon} alt="Mobile devices" />
                        </Icon>
                        <Label>A true mobile experience</Label>
                        <Description>
                            Your Authereum wallet goes where you go. 
                            Access your favorite dapps in any mobile browser or native app.
                        </Description>
                    </Feature>
                    <Feature>
                        <Icon style={{ backgroundColor:'#FF4940' }}>
                            <img src={seamlessIcon} alt="Infinity symbol with arrow" />
                        </Icon>
                        <Label>Seamless Onboarding</Label>
                        <Description>
                            Get up and running in seconds. 
                            Choose a username and password and you’re ready to explore the new web.
                        </Description>
                    </Feature>
                    <Feature>
                        <Icon style={{ backgroundColor:'#B252FF' }}>
                            <img src={peacefulMindIcon} alt="A peaceful smiley-face"/>
                        </Icon>
                        <Label>Peace of mind</Label>
                        <Description>
                            We put security at the forefront, 
                            giving you the peace of mind you need when using cryptocurrencies.
                        </Description>
                    </Feature>
                </FeatureWindow>  
                
            </Wrap>
        )
    }
}
