import React, { Component } from 'react'
import { Wrap, PartnerList, ListTitle, PartnerWrap, Partner } from './_styles'

import kickback from '../../images/partner-kickback.png'
import openzeppelin from '../../images/partner-openzeppelin.png'
import ens from '../../images/partner-ens.png'
//import web3Connect from '../../images/partner-web3connect.png'
//import blocknative from '../../images/partner-blocknative.png'
//import threebox from '../../images/partner-3box.png'
//import connext from '../../images/partner-connext.png'
//import maker from '../../images/partner-makerdao.png'


export default class Partners extends Component {
    render() {
        return (
            <Wrap>
                  {/*
                <ListTitle>Partners</ListTitle>
                <PartnerList>
                    <PartnerWrap href="https://kickback.events" rel="noopener noreferrer" target="_blank"><Partner src={ kickback } alt="link to kickback homepage" /></PartnerWrap>
                    <PartnerWrap href="https://web3connect.com" rel="noopener noreferrer" target="_blank"><Partner src={ web3Connect } alt="link to web3connect homepage" /></PartnerWrap>
                </PartnerList>
                */}
                <ListTitle>Integration Partners</ListTitle>
                <PartnerList>
                    <PartnerWrap href="https://openzeppelin.com" rel="noopener noreferrer" target="_blank"><Partner src={ openzeppelin } alt="link to openzeppelin homepage" /></PartnerWrap>
                    <PartnerWrap href="https://kickback.events" rel="noopener noreferrer" target="_blank"><Partner src={ kickback } alt="link to kickback homepage" /></PartnerWrap>
                    {/*<PartnerWrap href="https://3box.io" rel="noopener noreferrer" target="_blank"><Partner src={ threebox } alt="link to 3box homepage" /></PartnerWrap>*/}
                      {/*
                    <PartnerWrap href="https://www.blocknative.com" rel="noopener noreferrer" target="_blank"><Partner src={ blocknative } alt="link to blocknative homepage" /></PartnerWrap>
                    */}
                    {/*<PartnerWrap href="https://connext.network" rel="noopener noreferrer" target="_blank"><Partner src={ connext } alt="link to connext homepage" /></PartnerWrap>*/}
                    <PartnerWrap href="https://ens.domains" rel="noopener noreferrer" target="_blank"><Partner src={ ens } alt="link to ENS homepage"/></PartnerWrap>
                    {/*<PartnerWrap href="https://makerdao.com" rel="noopener noreferrer" target="_blank"><Partner src={ maker } alt="link to MakerDAO homepage" /></PartnerWrap>*/}
                </PartnerList>
            </Wrap>
        )
    }
}
