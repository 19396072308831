import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Cover from '../components/Cover'
import Features from '../components/Features'
import Control from '../components/Control'
import Partners from '../components/Partners'
import Integration from '../components/Integration'
import News from '../components/News'
import ContactUs from '../components/ContactUs'

const IndexPage = () => (
  <Layout>
    <SEO title="Authereum | Log into Ethereum" /> 
    <Cover />
    <Features />
    <Control />
    {/*<Partners />*/}
    <Integration />
    <News />
    <ContactUs />
  </Layout>
)

export default IndexPage
