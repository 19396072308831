import styled from 'styled-components'

export const Wrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background:#f2f6f9;
`

export const Title = styled.h2`
    margin: 5rem 0 1.25rem;
    font-size:2.25rem;
    text-align:center;
    color:#00172b;
`

export const Subtitle = styled.h6`
    margin:0 0 2.75rem;
    font-size:1.25rem;
    font-weight:400;
`

export const ContactForm = styled.form`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:38rem;
    max-width:calc(100% - 6rem);
    margin: 0 auto 5rem;
    input, textarea { 
        padding: 1.125rem 1.375rem;
        background: #ecf1f4;
        border:1px solid #e0e8ee;
        border-radius:5px;
        color:#00172b;
        outline:none;
    }
`

export const Error = styled.h6`
    margin:0 0 2rem;
    color:red;
    line-height:2.5;
    font-size:1rem;
`

export const Name = styled.input`
    width:calc(50% - .75rem);
    margin-right:1.5rem;
    margin-bottom:1.5rem;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        width:100%;
        margin-right:0;
    }
`
export const Email = styled.input`
    width:calc(50% - .75rem);
    margin-bottom:1.5rem;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        width:100%;
        margin-right:0;
    }
`
export const Message = styled.textarea`
    width:100%;
    min-height:7.5rem;
    margin-bottom:1.5rem;
`

export const SubmitButton = styled.button`
    width:100%;
    padding: 1.125rem 2.5rem .9375rem;
    background:linear-gradient(105deg, #ff473e, #ff6a63);
    border:none;
    border-radius:25px;
    text-decoration:none;
    line-height:1.5;
    font-size:1rem;
    font-weight:400;
    letter-spacing:0.09px;
    color:white;
    outline:none;
    cursor:pointer;
    transition: background 150ms ease-out;
    &[data-form-ready="false"]{
        opacity:.5;
        pointer-events:none;
    }
    &[data-submitting="true"]{
        pointer-events:none;
    }
    &[data-success="true"]{
        pointer-events:none;
        transition:background none;
        background:#ecf1f4;
        border:1px solid #e0e8ee;
        color:#00172b;
        &:hover, &:focus { background:#ecf1f4 }
    }
    &:hover, &:focus {
        background:linear-gradient(105deg, #ff575f, #ff8c85);
    }
`