import React, { Component } from "react"
import PropTypes from "prop-types"

import {
  Wrap,
  Navigation,
  Logo,
  NavSelect,
  NavLinkExternal,
  SignUp
} from './_styles'
import logo from '../../images/logo-full-white.png'

class Header extends Component {

  navigation = null;
  constructor(props){
    super(props);
    this.state = {
      windowScrolled:false,
      headerWidth: 0
    }
  }

  componentDidMount(){
    // Adds border on bottom of header after 5 pixels
    window.addEventListener('scroll', this.detectYPosition.bind(this))
    //Need to use javascript to manually set fixed header width for mobile
    this.setHeaderWidth();
    window.addEventListener('resize', this.setHeaderWidth.bind(this))
    // Staggered fadeIn to hide load times for font & images
    setTimeout(() => this.navigation.style.opacity = 1, 300)
  }

  setHeaderWidth(){
    this.setState({ headerWidth:window.outerWidth })
  }

  detectYPosition(){
    const { windowScrolled } = this.state;
    if ( windowScrolled && window.scrollY < 5 ){
      this.setState({ windowScrolled: false })
    } else if ( !windowScrolled && window.scrollY >= 5 ){
      this.setState({ windowScrolled: true })
    }

  }

  render() {
    const { windowScrolled, headerWidth } = this.state;
    return (
      <Wrap data-scrolled={ windowScrolled } style={{ width: headerWidth > 415 ? `100%` : `${headerWidth}px` }}>
      <Navigation ref={ref => this.navigation = ref }>
        <Logo onClick={() => window.scrollTo(0,0)} src={ logo } alt="Logo" />
        <NavSelect>
          {/* <NavLink to="">Solutions</NavLink> */}
          {/* <NavLink to="">Team</NavLink> */}
          <NavLinkExternal href="/login" rel="noopener noreferrer" target="_blank">Login</NavLinkExternal>
          {/*<SignUp href="/signup" rel="noopener noreferrer" target="_blank">Sign Up</SignUp>*/}
          <SignUp href="https://uniswap.demo.authereum.org" rel="noopener noreferrer" target="_blank">Try Demo</SignUp>
        </NavSelect>
      </Navigation>
    </Wrap>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
