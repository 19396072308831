import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export const Wrap = styled.div`
    position:fixed;
    top:0; left:0;
    width:100%;
    height:4rem;
    background:#00172b;
    z-index:999;
    border-bottom:1px solid transparent;
    transition:border-bottom 300ms ease-out;
    &[data-scrolled="true"]{
        border-bottom:1px solid rgba(0,0,0,.7);
    }
`

export const Navigation = styled.div`
    position:relative;
    display:flex;
    flex-direction:row;
    height:100%;
    width:54rem;
    max-width:calc(100% - 3rem);
    margin:0 auto;
    opacity:0;
    transition: opacity 300ms ease-out;
`

export const Logo = styled.img`
    height:calc(100% - 1.5rem);
    margin:.75rem 0;
    cursor:pointer;
`
export const NavSelect = styled.div`
    flex:1;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
`
export const NavLink = styled(props => <Link {...props} />)`
    margin:0 .5rem;
    color:white;
    font-size:.875rem;
    letter-spacing:0.09px;
    text-decoration:none;
    cursor:pointer;
    &:hover {
        text-decoration:underline;
    }
`

export const NavLinkExternal = styled.a`
    margin:0 .5rem;
    color:white;
    font-size:.875rem;
    letter-spacing:0.09px;
    text-decoration:none;
    cursor:pointer;
    &:hover {
        text-decoration:underline;
    }
`

export const SignUp = styled.a`
    margin-left:1.625rem;
    padding:.625rem 1.125rem .5rem;
    background:none;
    border:solid 1px rgba(255,255,255,.6);
    border-radius:18px;
    color:white;
    text-decoration:none;
    font-size:.8125rem;
    font-weight:800;
    cursor:pointer;
    outline:none;
    transition: background 150ms ease-out;
    &:hover {
        background:rgba(255,255,255,.08);
    }
`