import styled from 'styled-components'

export const Wrap = styled.div`
    display:flex;
    position:relative;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background:#FFF;
    padding: 3rem 0 6rem;
`

export const PartnerList = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    width:52rem;
    max-width:calc(100% - 8rem);
`

export const ListTitle = styled.h2`
    width:100%;
    margin: 3.5rem 0 1.25rem;
    font-size:2.25rem;
    text-align:center;
    color:#00172b;
`

export const PartnerWrap = styled.a`
    width:25%;
    margin: 0 2%;
    padding: 0 2%;
    display:flex;
    align-items:center;
    justify-content:center;
    height:8rem;
    border-radius:12px;
    cursor:pointer;
    outline:none;
    transition: background 150ms ease-out;
    &:hover, &:focus {
        background:#FFF9F9;
    }
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        width: 42%;
    }
`

export const Partner = styled.img`
    max-width: calc(100% - .125rem);
    max-height: calc(100% - .375rem);
`