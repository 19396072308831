import styled from 'styled-components'

export const Wrap = styled.div`
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:center;
    width:100%;
    background:#00172b;
`

export const Body = styled.div`
    position:relative;
    display:flex;
    width:100%;
    flex-direction:column;
    align-items:center;
    opacity:0;
    transition: opacity 300ms ease-out;
`

export const Title = styled.h1`
    width:100%;
    max-width:calc(100% - 4rem);
    margin: 8.125rem 0 1.125rem;
    font-size:2.5rem;
    letter-spacing:1.1px;
    text-align:center;
    color:white;
`

export const Subtitle = styled.h4`
    width:100%;
    max-width:calc(100% - 4rem);
    margin:0 0 2.125rem;
    font-size:1.5rem;
    font-weight:200;
    line-height:1.38;
    letter-spacing:0.24px;
    text-align:center;
    color:white;
`

export const WaitList = styled.div`
    display:flex;
    width:34rem;
    max-width:calc(100% - 6.5rem);
    height:8.75rem;
    margin-bottom:1.5rem;
    padding-top:1.625rem;
    flex-direction:column;
    border-top:1px solid rgba(255,255,255,.05);
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        margin-bottom:3.875rem;
    }
`

export const Prompt = styled.h4`
    width:100%;
    margin: .25rem 0 1.625rem;
    text-align:center;
    font-size:1rem;
    font-weight:400;
    letter-spacing:.25px;
    color:#FFF;
    opacity:.4;
`

export const Error = styled.h4`
    margin:-1rem auto .5rem;
    color:#ff6761;
    font-size:1rem;
    font-weight:400;
`

export const SuccessText = styled.h4`
    position:relative;
    flex:1;
    display:flex;
    flex-direction:row;
    align-items:center;
    width:100%;
    margin: 0 auto 1.125rem;
    border:1px dotted rgba(255,255,255,.6);
    border-radius:5px;
    color:white;
    justify-content:center;
`

export const Form = styled.form`
    position:relative;
    flex:1;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding: 0 0 1.375rem;
`

export const Email = styled.input`
    width:calc(70% - 1.125rem);
    height:100%;
    padding: .75rem 1rem;
    background: #ecf1f4;
    border:1px solid #e0e8ee;
    border-radius:5px;
    color:#00172b;
    outline:none;
`
export const Submit = styled.button`
    width:30%;
    padding: .875rem 0 .75rem;    
    background:linear-gradient(105deg, #ff473e, #ff6a63);
    border:none;
    border-radius:5px;
    text-decoration:none;
    font-size:.875rem;
    font-weight:600;
    letter-spacing:0.12px;
    color:white;
    outline:none;
    cursor:pointer;
    transition: background 150ms ease-out;
    &:hover, &:focus {
        background:linear-gradient(105deg, #ff575f, #ff8c85);
    }
`

export const Images = styled.div`
    flex:1;
    display:flex;
    justify-content:flex-end;
    max-width:100%;
    overflow:hidden;
`

export const IPhone = styled.img`
    position:absolute;
    bottom:0;
    width:18rem;
    left: calc(50% - 9rem);
    z-index:1;
`

export const Macbook = styled.img`
    width:46rem;
    max-width:calc(100% - 3rem);
    margin:0 auto;
`