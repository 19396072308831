import styled from 'styled-components'

export const Wrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:10rem;
    background:#00172b;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        height:13.5rem;
    }
`

export const Body = styled.div`
    display:flex;
    flex-direction:row;
    width:62rem;
    max-width:calc(100% - 4rem);
    margin-top:2.875rem;
`

export const Links = styled.div`
    flex:2;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
`

export const Link = styled.a`
    padding: 0 .9375rem;
    margin-bottom:1.625rem;
    text-decoration:none;
    font-weight:300;
    color:#f2f6f9;
    letter-spacing:.12px;
    line-height:1.44;
    transition: color 150ms ease-out;
    cursor:pointer;
    &:focus, &:hover {
        color:white;
        text-decoration:underline;
    }
`

export const Copyright = styled.span`
    flex:1;
    font-weight:100;
    line-height:1.44;
    letter-spacing:.08px;
    color:#f2f6f9;
`