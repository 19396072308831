import styled from 'styled-components'

export const Wrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background:#f2f6f9;
`

export const Title = styled.h2`
    margin: 5.5rem 0 1.875rem;
    font-size:2.25rem;
    text-align:center;
    color:#00172b;
`

export const Body = styled.p`
    width:42rem;
    max-width:calc(100% - 4rem);
    margin:0;
    text-align:center;
    line-height:1.5;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        font-size:1.25rem;
        max-width:calc(100% - 8rem);
    }
`

export const Shield = styled.img`
    width:28rem;
    max-width:calc(100% - 6rem);
`