import styled from 'styled-components'

export const Wrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    background:#FFF;
    opacity:0;
    transition:opacity 150ms ease-out;
`

export const Title = styled.h2`
    margin: 5.25rem 0 4rem;
    font-size:2.25rem;
    text-align:center;
    color:#00172b;
`

export const FeatureWindow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    width:55.5rem;
    max-width:calc(100% - 4rem);
    margin-bottom:6.75rem;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        flex-direction:column;
        margin-bottom:4rem;
        max-width:calc(100% - 8rem);
    }
`

export const Feature = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    margin: 0 .5rem;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        margin-bottom:2rem;
    }
`

export const Icon = styled.div`
    display:flex;
    justify-content:center;
    align-items:center; 
    width:10rem;
    min-height:10rem;
    border-radius:1000px;
`

export const Label = styled.h6`
    font-size:1rem;
    text-align:center;
    margin: 2.25rem 0 .75rem;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        font-size:1.5rem;
    }
`

export const Description = styled.p`
    max-width:calc(100% - 1rem);
    text-align:center;
    font-size:1rem;
    line-height:1.5;
    @media screen
    and (min-width: 0)
    and (max-width: 415px){
        font-size:1.25rem;
    }
`