import React, { Component } from 'react'

import { Wrap, Title, Body, BodyColumn, Icon, Label, Description, CodeSnippet } from './_styles'
import integrateWeb3Connect from '../../images/integrate-web3connect.svg'
import integrateDirect from '../../images/integrate-direct.svg'

export default class Integration extends Component {
    render() {
        return (
            <Wrap>
                <Title>Add Authereum to your Dapp</Title>
                <Body>
                    <BodyColumn>
                        <Icon src={integrateWeb3Connect} alt="Web3Connect" />
                        <Label>Integrate with Web3Connect</Label>
                        <Description>
                            Web3Connect is an easy-to-use library to help developers add support for multiple
                            providers in their apps with a simple customizable configuration.
                        </Description>
                        <Description>
                            By default, the Web3Connect Library supports injected providers (Metamask, Dapper,
                            Gnosis Safe, Web3 Browsers, etc.) and WalletConnect. You can also easily configure
                            the library to support Fortmatic and Portis.
                        </Description>
                    </BodyColumn>
                    <BodyColumn>
                        <Icon src={integrateDirect} alt="Authereum"/>
                        <Label>Integrate with Authereum Directly</Label>
                        <Description>
                            Add Ethereum to your dapp with just a few lines of code.
                        </Description>
                        <CodeSnippet>
{`import Authereum from 'authereum'
import Web3 from 'web3'

const authereum = new Authereum('kovan')
const web3 = new Web3(authereum.getProvider())`}
                        </CodeSnippet>
                    </BodyColumn>
                </Body>

            </Wrap>
        )
    }
}
