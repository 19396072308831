import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'
import { Wrap, Title, Subtitle, ContactForm, Error, Name, Email, Message, SubmitButton } from './_styles'

export default class ContactUs extends Component {
    
    contactForm = null;
    constructor(props){
        super(props);
        this.state = {
            name:'',
            email:'',
            message:'',
            submitting:false,
            submitSuccess:null
        }
    }

    updateContactField = (field, value) => {
        this.setState({ [`${field}`]:value, submitSuccess:null })
    }

    submitContact = async () => {
        const { name, email, message } = this.state;
        
        //TODO: Actually validate fields
        if ( name && email ){
            this.setState({ submitting: true }, async () => {
                try {
                    const url = `https://${process.env.REACT_APP_API_HOST}/v1/contact`
                    const res = await fetch(url, {
                      method:'POST',
                      body:JSON.stringify({ name, email, message }),
                      headers: { 'Content-Type': 'application/json' }
                    })
                    res.status === 200 ?
                        this.setState({ submitSuccess:true, submitting:false }) :
                        this.setState({ submitSuccess:false, submitting:false })
                } catch ( error ){
                    //TODO: Actually handle different error types
                    this.setState({ submitSuccess:false, submitting: false })
                }
            });

        }
    }

    render() {
        const { name, email, message, submitting, submitSuccess } = this.state;
        const { submitContact, updateContactField } = this;
        return (
            <Wrap>
                <Title>Contact Us</Title>
                <Subtitle>Drop us a line and see what we're up to</Subtitle>
                { submitSuccess === false ? <Error>Something went wrong... Please try again!</Error> : ''}
                <ContactForm 
                    ref={ref => this.contactForm = ref } 
                    data-contact-submitted={ submitSuccess }
                    onSubmit={e => e.preventDefault() && submitContact() }>
                    { submitting || submitSuccess ? 
                        <Name name="name" placeholder="Name" disabled value={ name }/> :
                        <Name 
                            name="name" placeholder="Name"
                            onChange={e => updateContactField('name', e.target.value)}
                            onKeyDown={e => e.keyCode === 13 ? submitContact() : false }
                            value={ name }
                        />}
                    { submitting || submitSuccess ? 
                        <Email name="email" placeholder="Email" disabled value={ email } /> :
                        <Email
                            name="email" placeholder="Email"
                            onChange={e => updateContactField('email', e.target.value)}
                            onKeyDown={e => e.keyCode === 13 ? submitContact() : false }
                            value={ email }
                        /> }
                    { submitting || submitSuccess ? 
                        <Message name="message" placeholder="Message" row="7" disabled value={ message } /> :
                        <Message 
                            name="message" placeholder="Message" row="7"
                            onChange={e => updateContactField('message', e.target.value)}
                            value={ message }
                        /> }
                    <SubmitButton
                        data-form-ready={!!name && !!email}
                        data-submitting={ submitting }
                        data-success={ this.state.submitSuccess || false }
                        onClick={ !submitting && submitSuccess !== true ? submitContact : false }>
                        { submitting ? 'Delivering Message...' : 
                            submitSuccess ? `Thanks for the message ${name} - We'll get back to you soon!` : 
                                            'Submit' 
                        }
                    </SubmitButton>
                </ContactForm>
            </Wrap>
        )
    }
}
