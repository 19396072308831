import React, { Component } from 'react'
import { Wrap, Title, Body, Shield } from './_styles'

import shield from '../../images/shield.svg'

export default class Control extends Component {
    render() {
        return (
            <Wrap>
                <Title>You're in control</Title>
                <Body>
                    Only you can access your funds. 
                    Each account is 100% non-custodial, 
                    allowing you and only you to access your funds. 
                    You’re safe even if our servers get compromised.
                </Body>
                <Shield src={shield} alt="A shield protecting your Authereum wallet" />
            </Wrap>
        )
    }
}
